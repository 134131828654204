define("mi-guatemala/templates/components/portfolio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dySnGN+w",
    "block": "{\"symbols\":[\"profile\",\"@pagination\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-10 offset-1\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"portfolio\"],[10,\"class\",\"portfolio clearfix my-4\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"content\"]]],null,{\"statements\":[[0,\"        \"],[5,\"item-portfolio\",[],[[\"@profile\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-4 offset-4 text-center\"],[8],[0,\"\\n      \"],[1,[28,\"page-numbers\",null,[[\"content\"],[[24,[\"content\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mi-guatemala/templates/components/portfolio.hbs"
    }
  });

  _exports.default = _default;
});
define("mi-guatemala/templates/perfil/frente-a-frente", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BnL26t/h",
    "block": "{\"symbols\":[],\"statements\":[[5,\"frente-a-frente\",[],[[\"@model\",\"@perfilUno\",\"@perfilDos\"],[[22,\"model\"],[22,\"perfilUno\"],[22,\"perfilDos\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mi-guatemala/templates/perfil/frente-a-frente.hbs"
    }
  });

  _exports.default = _default;
});
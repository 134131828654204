define("mi-guatemala/templates/components/profile-functionalities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dCdmFcGz",
    "block": "{\"symbols\":[\"nav\",\"link\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-block d-md-none text-right\"],[8],[0,\"\\n  \"],[1,[22,\"share-twitter\"],false],[0,\"\\n  \"],[1,[22,\"share-facebook\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row my-2\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 p-0 border shadow\"],[8],[0,\"\\n    \"],[5,\"bs-nav\",[],[[\"@type\",\"@justified\",\"@stacked\",\"@fill\"],[\"pills\",true,false,true]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"links\"]]],null,{\"statements\":[[0,\"        \"],[6,[23,1,[\"item\"]],[],[[\"@linkTo\"],[[23,2,[\"route\"]]]],{\"statements\":[[0,\"\\n          \"],[1,[23,2,[\"text\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[6,[23,1,[\"item\"]],[[12,\"class\",\"social\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[1,[22,\"share-twitter\"],false],[0,\"\\n          \"],[1,[22,\"share-facebook\"],false],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mi-guatemala/templates/components/profile-functionalities.hbs"
    }
  });

  _exports.default = _default;
});
define("mi-guatemala/components/item-portfolio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const resolver = {
    institution: 'instituciones',
    election: 'elecciones',
    profile: 'perfiles'
  };

  class ItemPortfolioComponent extends Ember.Component {
    constructor(...args) {
      super(...arguments);
      this.set('modelName', null);
    }

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('modelName', resolver[this.profile._internalModel.modelName]);
    }

  }

  _exports.default = ItemPortfolioComponent;
});
define("mi-guatemala/models/institution", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Attributes
    nombre: (0, _model.attr)('string'),
    sector: (0, _model.attr)('string'),
    mision: (0, _model.attr)('string'),
    vision: (0, _model.attr)('string'),
    correo: (0, _model.attr)('string'),
    telefono: (0, _model.attr)('string'),
    fb: (0, _model.attr)('string'),
    tw: (0, _model.attr)('string'),
    fotoURL: (0, _model.attr)('string'),
    // Relationships
    members: (0, _model.hasMany)('profile', {
      inverse: 'institution'
    }),
    comision: (0, _model.hasMany)('election'),
    // Computed properties

    /**
     * This computed property return photoURL if is not blank, else return a default image.
     */
    photo: Ember.computed('fotoURL', function () {
      if (!Ember.isBlank(this.fotoURL)) {
        return this.fotoURL;
      }

      return 'http://centrumnaukiwesola.pl/wp-content/themes/bulhak-edu/img/default-avatar.png';
    })
  });

  _exports.default = _default;
});
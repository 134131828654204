define("mi-guatemala/templates/perfil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9inAa6CF",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"model\",\"profile\",\"nombre\"]]],[[\"replace\"],[true]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"w-100 bg-light perfil-container\"],[8],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"container profile my-4 py-3\"],[8],[0,\"\\n\\n    \"],[5,\"profile-functionalities\",[],[[\"@model\"],[[22,\"model\"]]]],[0,\"\\n\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mi-guatemala/templates/perfil.hbs"
    }
  });

  _exports.default = _default;
});
define("mi-guatemala/routes/perfil/index", ["exports", "mi-guatemala/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      if (model.profile._internalModel.modelName === 'institution') {
        controller.set('campos', ['nombre', 'sector', 'telefono']);
      }

      if (model.profile._internalModel.modelName === 'profile') {
        controller.set('campos', ['puesto', 'estadocivil', 'profesion', 'nocolegiado', 'anosexperiencia']);
      }

      if (model.profile._internalModel.modelName === 'election') {
        controller.set('campos', ['nombre', 'fechaEleccion']);
      }

      controller.setProperties(model);
      controller.setProperties({
        disqusShortname: _environment.default.disqus.shortname,
        years: true,
        charge: true
      });
    }

  });

  _exports.default = _default;
});
define("mi-guatemala/adapters/application", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/adapter"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  let ApplicationAdapter = (_class = (_temp = class ApplicationAdapter extends _adapter.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "spreadsheets", _descriptor, this);
    }

    createRecord() {
      throw 'Not supported';
    }

    updateRecord() {
      throw 'Not supported';
    }

    deleteRecord() {
      throw 'Not supported';
    }

    findRecord(store, type, id) {
      return this.spreadsheets.fetch(type.modelName).then(objects => {
        return objects.findBy('id', id);
      });
    }

    findAll(store, type) {
      return this.spreadsheets.fetch(type.modelName);
    }

    query(store, type, query) {
      return this.spreadsheets.fetch(type.modelName).then(objects => {
        return objects.filter(object => {
          return !Object.keys(query).some(key => {
            if (object[key] !== query[key]) {
              return true;
            }
          });
        });
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "spreadsheets", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ApplicationAdapter;
});
define("mi-guatemala/models/partido", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Model de partido político.
   *
   * @class Model.Party
   */
  var _default = _model.default.extend({
    // Attributes

    /**
     * Código - Abreviación
     *
     * @property codigo
     */
    codigo: (0, _attr.default)(),

    /**
     * Nombre completo
     *
     * @property nombreCompleto
     * @type String
     */
    nombreCompleto: (0, _attr.default)('string'),

    /**
     * Nombre corto
     *
     * @property nombre
     * @type String
     */
    nombreCorto: (0, _attr.default)('string'),

    /**
     * Fecha Inscripción
     *
     * @property fechaInscripcion
     * @type String
     */
    fechaInscripcion: (0, _attr.default)('string'),

    /**
     * Secretario General
     *
     * @property secretarioGeneral
     * @type String
     */
    secretarioGeneral: (0, _attr.default)('string'),

    /**
     * Perfil de Facebook, la URL.
     *
     * @property fb
     * @type String
     */
    fb: (0, _attr.default)('string'),

    /**
     * Perfil de Twitter, la URL.
     *
     * @property tw
     * @type String
     */
    tw: (0, _attr.default)('string'),

    /**
     * Logo
     *
     * @property logo
     * @type String
     */
    logoURL: (0, _attr.default)('string'),
    numeroAfiliados: (0, _attr.default)('string'),
    // Relationships

    /**
     * Miembros del partido
     *
     * @property members
     * @type String
     */
    miembros: (0, _relationships.hasMany)('candidate', {
      inverse: null
    }),
    // Computed
    logo: Ember.computed('logoURL', function () {
      if (this.logoURL) {
        return this.logoURL;
      }

      return 'img/partido-default.png';
    })
  });

  _exports.default = _default;
});
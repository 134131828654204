define("mi-guatemala/models/candidate", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Modelo base para candidatos.
   *
   * @class Model.Candidate
   */
  var _default = _model.default.extend({
    // Attributes

    /**
     * Nombre del candidato
     *
     * @property nombre
     * @type String
     */
    nombre: (0, _attr.default)('string'),
    // FBM
    web: (0, _attr.default)('string'),
    correo: (0, _attr.default)('string'),

    /**
     * Id del partido
     *
     * @property partido
     * @type Number
     */
    partido: (0, _relationships.belongsTo)('partido'),

    /**
     * edad
     *
     * @property edad
     * @type Number
     */
    edad: (0, _attr.default)('number'),

    /**
     * estadoCivil
     *
     * @property estadoCivil
     * @type String
     */
    estadoCivil: (0, _attr.default)('string'),

    /**
     * fechaDeNacimiento
     *
     * @property fechaDeNacimiento
     * @type String
     */
    fechaDeNacimiento: (0, _attr.default)('string'),

    /**
     * lugarDeNacimiento
     *
     * @property lugarDeNacimiento
     * @type String
     */
    lugarDeNacimiento: (0, _attr.default)('string'),

    /**
     * anosProfesional
     *
     * @property anosProfesional
     * @type Number
     */
    anosProfesional: (0, _attr.default)('number'),

    /**
     * numeroColegiado
     *
     * @property numeroColegiado
     * @type Number
     */
    numeroColegiado: (0, _attr.default)('number'),

    /**
     * sexo
     *
     * @property sexo
     * @type String
     */
    sexo: (0, _attr.default)('string'),

    /**
     * fb
     *
     * @property fb
     * @type String
     */
    fb: (0, _attr.default)('string'),

    /**
     * tw
     *
     * @property tw
     * @type String
     */
    tw: (0, _attr.default)('string'),

    /**
     * fotoURL
     *
     * @property fotoURL
     * @type String
     */
    fotoURL: (0, _attr.default)('string'),
    // Computed properties

    /**
    * Selector para Isotope. Genera un 'String' con etiquetas para poder filtrar elementos desde el HTML con Isotope.
    *
    * @property selector
    * @type String
    * @default ""
    */
    selector: Ember.computed('sexo', 'estado', 'partido', function () {
      let returnValue = '';

      if (this.sexo === 'Masculino') {
        returnValue += ' hombre';
      }

      if (this.sexo === 'Femenino') {
        returnValue += ' mujer';
      }

      if (this.estado === 'Descalificado') {
        returnValue += ' descalificado';
      }

      if (this.estado === 'En proceso') {
        returnValue += ' enProceso';
      }

      if (this.type) {
        returnValue += ' ' + this.type;
      }

      if (this.partido) {
        returnValue += ' ' + this.partido.get('nombreCorto').dasherize();
      }

      return returnValue;
    }),

    /**
     * Foto del perfil, en el caso que fotoURL este vacío se verifica el sexo y se establece una imagen por defecto respectivamente.
     *
     * @param fotoURL String enlace de la foto
     * @param sexo Sexo del candidato.
     * @return String enlace de la foto del candidato
     */
    fotoPerfil: Ember.computed('fotoURL', 'sexo', function () {
      if (!Ember.isBlank(this.fotoURL)) {
        return this.fotoURL;
      }

      if (this.sexo === 'Masculino') {
        return 'mi-guatemala/img/candidato.png';
      }

      if (this.sexo === 'Femenino') {
        return 'mi-guatemala/img/candidata.png';
      }

      if (parseInt(this.id.slice(this.id.indexOf('-') + 1)) % 2 === 0) {
        return 'mi-guatemala/img/candidata.png';
      }

      return 'mi-guatemala/img/candidato.png';
    })
  });

  _exports.default = _default;
});
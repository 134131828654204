define("mi-guatemala/components/frente-a-frente", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    availableInfoUno: Ember.computed('perfilUno', function () {
      let data = this.model.info.findBy('id', this.get('perfilUno.id'));

      if (this.model.historial.filterBy('perfil', this.get('perfilUno.id'))) {
        data['historial'] = this.model.historial.filterBy('perfil', this.get('perfilUno.id'));
      }

      return data;
    }),
    availableInfoDos: Ember.computed('perfilDos', function () {
      let data = this.model.info.findBy('id', this.get('perfilDos.id'));

      if (this.model.historial.filterBy('perfil', this.get('perfilDos.id'))) {
        data['historial'] = this.model.historial.filterBy('perfil', this.get('perfilDos.id'));
      }

      return data;
    })
  });

  _exports.default = _default;
});
define("mi-guatemala/helpers/twitter-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.twitterLink = twitterLink;
  _exports.default = void 0;

  function twitterLink(params
  /*, hash*/
  ) {
    let twitterProfile = params[0].replace('https://twitter.com/', '');
    let cutIndex = twitterProfile.indexOf('?');
    twitterProfile = twitterProfile.slice(0, cutIndex);
    return twitterProfile;
  }

  var _default = Ember.Helper.helper(twitterLink);

  _exports.default = _default;
});
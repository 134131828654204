define("mi-guatemala/router", ["exports", "ember-router-scroll", "mi-guatemala/config/environment"], function (_exports, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    // Servicio para Google Analytics (ember-metrics)
    metrics: Ember.inject.service(),

    // Llamar a '_trackPage' en cada transición
    didTransition() {
      this._super(...arguments);

      this._trackPage();
    },

    // Registra la página visitada con el servicio 'metrics'
    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = this.url;
        const title = this.getWithDefault('currentRouteName', 'unknown');
        this.metrics.trackPage({
          page,
          title
        });
      });
    }

  });
  Router.map(function () {
    /**
     * Perfil ya sea de una institución o persona carga información dependiendo de eso. Es decir la ruta 'perfil' es el mismo para los dos.
     * Las subrutas en cambia varian según el modelo (institución o persona) y deberian detener la transición si se trata de ingresar a una ruta con un modelo inválido.
     */
    this.route('perfil', {
      path: '/:model/:id'
    }, function () {
      this.route('autoridades');
      this.route('comision');
      this.route('frente-a-frente');
      this.route('candidatos');
    });
    this.route('perfiles', {
      path: '/:model'
    });
  });
  var _default = Router;
  _exports.default = _default;
});
define("mi-guatemala/components/profile-functionalities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init({
      model: {
        profile
      }
    }) {
      this._super(...arguments);

      this.set('links', Ember.A([{
        route: 'perfil.index',
        img: 'img/icono-perfil.png',
        text: 'Información general'
      }]));

      if (profile._internalModel.modelName === "institution") {
        this.links.pushObjects([{
          route: 'perfil.autoridades',
          img: '',
          text: 'Autoridades'
        }, {
          route: 'perfil.comision',
          img: '',
          text: 'Comisión de postulación'
        }]);
      }

      if (profile._internalModel.modelName === "profile") {// this.links.pushObjects([
        //   { route: 'perfil.frente-a-frente', img: '', text: 'Compara'},
        // ]);
      }

      if (profile._internalModel.modelName === "election") {
        this.links.pushObjects([{
          route: 'perfil.candidatos',
          img: '',
          text: 'Candidatos'
        }]);
      }
    }

  });

  _exports.default = _default;
});
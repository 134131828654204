define("mi-guatemala/templates/components/disqus-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "btavj2di",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header d-flex\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"mi-guatemala/img/icon-comments.png\"],[10,\"alt\",\"Comentarios\"],[8],[9],[0,\"\\n        \"],[7,\"h3\",true],[8],[0,\"Comentarios\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n        \"],[1,[28,\"disqus-comments\",null,[[\"identifier\"],[[24,[\"profile\",\"id\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mi-guatemala/templates/components/disqus-panel.hbs"
    }
  });

  _exports.default = _default;
});
define("mi-guatemala/components/share-panel", ["exports", "ember-social-share/components/share-panel"], function (_exports, _sharePanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _sharePanel.default;
    }
  });
});
define("mi-guatemala/templates/components/share-twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iQasXsp1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"twitter-share-button\",null,[[\"title\",\"hashtags\",\"via\"],[\"¡Encuentra aquí la información y perfiles de los candiDatos.gt!\",\"Elecciones2019, MochilaElectoral, CandiDatos\",\"Redxguate\"]],{\"statements\":[[0,\"  Tweet\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mi-guatemala/templates/components/share-twitter.hbs"
    }
  });

  _exports.default = _default;
});
define("mi-guatemala/initializers/main", ["exports", "debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * Oculta las depreciaciones.
   *
   * @class Initializer.Main
   */
  function initialize()
  /* application */
  {
    /**
     * Herramienta para generar logs.
     * @type debug
     */
    let log = (0, _debug.default)('initializer:main');
    log('The deprecations are hidden but are showing in Ember\'s Inspector deprecations tab. See main initializer for more information.');
    /**
     * Oculta las depreciaciones.Referencia: https://guides.emberjs.com/v3.4.0/configuring-ember/handling-deprecations/.
     *
     * @method registerDeprecationHandler
     */

    Ember.Debug.registerDeprecationHandler((message, options, next) => {
      if (options && options.until && options.until !== '3.0.0') {
        return;
      } else {
        next(message, options);
      }
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});
define("mi-guatemala/components/portfolio", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      if (!this.pagination) {
        this.set('perPage', this.profiles.length);
      }
    },

    classNames: ['container-fluid'],
    classNameBindings: ['background'],
    background: Ember.computed('bg', function () {
      return "bg-".concat(this.bg);
    }),
    // Pagination
    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 50,
    content: (0, _pagedArray.default)('profiles', {
      page: Ember.computed.alias("parent.page"),
      perPage: Ember.computed.alias("parent.perPage")
    }),
    totalPages: Ember.computed.oneWay("pagedContent.totalPages")
  });

  _exports.default = _default;
});
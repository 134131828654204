define("mi-guatemala/helpers/get-html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getHtmlSafe = getHtmlSafe;
  _exports.default = void 0;

  function getHtmlSafe([object, propertyName]) {
    if (Ember.isNone(object)) {
      return '';
    }

    let frenteAFrente = Ember.get(object, 'frenteAFrente');

    if (Ember.isNone(frenteAFrente)) {
      return '';
    }

    return Ember.String.htmlSafe(Ember.get(frenteAFrente, propertyName));
  }

  var _default = Ember.Helper.helper(getHtmlSafe);

  _exports.default = _default;
});
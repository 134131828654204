define("mi-guatemala/routes/perfil/frente-a-frente", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const types = {
    presidentes: 'president',
    listado: 'listado',
    distrito: 'distrito',
    parlacen: 'parlacen',
    alcaldes: 'mayor'
  };

  var _default = Ember.Route.extend({
    types: types,
    spreadsheets: Ember.inject.service(),

    model() {
      const spreadsheet = this.spreadsheets;
      let modelData = Ember.A();
      let app = this.modelFor('perfil');
      modelData = this.modelFor('application')[app.profile.type + 's'].toArray();
      return Ember.RSVP.hash({
        profiles: modelData,
        info: spreadsheet.fetch('info-' + app.profile.type),
        historial: spreadsheet.fetch('historial')
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('perfil.frente-a-frente').set('perfilUno', model.profiles.firstObject);
      this.controllerFor('perfil.frente-a-frente').set('perfilDos', model.profiles.firstObject);
    }

  });

  _exports.default = _default;
});
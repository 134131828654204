define("mi-guatemala/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vd7K0ar8",
    "block": "{\"symbols\":[\"profile\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid bg-light py-4\"],[8],[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-10 offset-1\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"id\",\"portfolio\"],[10,\"class\",\"portfolio clearfix my-4\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"          \"],[5,\"item-portfolio\",[],[[\"@profile\"],[[23,1,[]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mi-guatemala/templates/index.hbs"
    }
  });

  _exports.default = _default;
});
define("mi-guatemala/components/link-to-wrapper", ["exports", "ember-link-to-wrapper/components/link-to-wrapper"], function (_exports, _linkToWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _linkToWrapper.default;
    }
  });
});
define("mi-guatemala/templates/components/item-portfolio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+jbr6bxY",
    "block": "{\"symbols\":[\"@profile\"],\"statements\":[[7,\"article\",true],[11,\"class\",[29,[\"item \",[23,1,[\"selector\"]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"background bg-white\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"image\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@models\"],[\"perfil\",[28,\"array\",[[23,0,[\"modelName\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"img\",true],[11,\"alt\",[23,1,[\"name\"]]],[11,\"src\",[23,1,[\"photo\"]]],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-name\"],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\",\"@models\"],[\"perfil\",[28,\"array\",[[23,0,[\"modelName\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n          \"],[7,\"h6\",true],[8],[1,[23,1,[\"name\"]],false],[1,[23,1,[\"nombre\"]],false],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"h6\",true],[10,\"class\",\"font-weight-light\"],[8],[1,[23,1,[\"electionName\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mi-guatemala/templates/components/item-portfolio.hbs"
    }
  });

  _exports.default = _default;
});
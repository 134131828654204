define("mi-guatemala/controllers/institucion", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Pagination
    // setup our query params
    queryParams: ["page", "perPage"],
    // set default values, can cause problems if left out
    // if value matches default, it won't display in the URL
    page: 1,
    perPage: 10,
    // can be called anything, I've called it pagedContent
    // remember to iterate over pagedContent in your template
    pagedContent: (0, _pagedArray.default)('model.profiles', {
      page: Ember.computed.alias("parent.page"),
      perPage: Ember.computed.alias("parent.perPage")
    }),
    // binding the property on the paged array
    // to a property on the controller
    totalPages: Ember.computed.oneWay("pagedContent.totalPages")
  });

  _exports.default = _default;
});
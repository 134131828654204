define("mi-guatemala/routes/perfiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const resolver = {
    instituciones: 'institution',
    elecciones: 'election',
    perfiles: 'profile'
  };

  var _default = Ember.Route.extend({
    resolver: resolver,
    queryParams: {
      sector: {
        refreshModel: true
      }
    },

    model({
      model,
      sector
    }) {
      let modelName = this.resolver[model];

      if (sector) {
        return this.store.query(modelName, {
          sector: sector
        });
      }

      return this.store.findAll(modelName);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('config', model.firstObject);
    }

  });

  _exports.default = _default;
});
define("mi-guatemala/components/disqus-comments", ["exports", "ember-disqus/components/disqus-comments"], function (_exports, _disqusComments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _disqusComments.default;
    }
  });
});
define("mi-guatemala/initializers/component-styles", ["exports", "ember-component-css/initializers/component-styles", "mi-guatemala/mixins/style-namespacing-extras"], function (_exports, _componentStyles, _styleNamespacingExtras) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _componentStyles.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _componentStyles.initialize;
    }
  });
  // eslint-disable-next-line ember/new-module-imports
  Ember.Component.reopen(_styleNamespacingExtras.default);
});
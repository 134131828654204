define("mi-guatemala/models/profile", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base model for people
   */
  var _default = _model.default.extend({
    // Attributes
    nombre: (0, _model.attr)('string'),
    sexo: (0, _model.attr)('string'),
    puesto: (0, _model.attr)('string'),
    dependencia: (0, _model.attr)('string'),
    departamento: (0, _model.attr)('string'),
    institucion: (0, _model.attr)('string'),
    fechalaboral: (0, _model.attr)('string'),
    nocolegiado: (0, _model.attr)('string'),
    estadocivil: (0, _model.attr)('string'),
    profesion: (0, _model.attr)('string'),
    anosexperiencia: (0, _model.attr)('string'),
    experienciaProfesional: (0, _model.attr)('string'),
    experienciaAcademica: (0, _model.attr)('string'),
    publicaciones: (0, _model.attr)('string'),
    fotoURL: (0, _model.attr)('string'),
    // Relationships
    institution: (0, _model.belongsTo)('institution'),
    comission: (0, _model.belongsTo)('institution'),
    election: (0, _model.belongsTo)('election'),
    // Computed properties

    /**
     * This computed property set a default image if fotoURL is blank.
     */
    photo: Ember.computed('fotoURL', 'sexo', function () {
      if (!Ember.isBlank(this.fotoURL)) {
        return this.fotoURL;
      }

      if (this.sexo === 'Masculino') {
        return 'mi-guatemala/img/candidato.png';
      }

      if (this.sexo === 'Femenino') {
        return 'mi-guatemala/img/candidata.png';
      }

      return 'mi-guatemala/img/candidata.png';
    })
  });

  _exports.default = _default;
});